import Vue from 'vue'
import App from './App.vue'
import router from './router'

import * as echarts from 'echarts'
import filters from './filters'
import axios from "axios"
import Cookies from 'js-cookie'
import ElementUI from 'element-ui'
import 'element-ui/lib/theme-chalk/index.css'
import JSEncrypt from 'jsencrypt' // 加密
import store from './store'
import './permission'

Vue.config.productionTip = false
Vue.prototype.$echarts = echarts
Vue.prototype.axios = axios
filters(Vue)

Vue.use(ElementUI)
Vue.prototype.$store = store
// 定义全局的elementUI输入框聚焦
Vue.directive('focus', { inserted: function(el) { el.children[0].focus() } })
Object.keys(filters).forEach(key => {
  Vue.filter(key, filters[key])
})
// 定义全局的密码传输的加密
Vue.prototype.$getRsaCode = function(str) { // 注册方法
  const pubKey = localStorage.getItem('PublicKey') // 引用 rsa 公钥
  const encryptStr = new JSEncrypt()
  encryptStr.setPublicKey(pubKey) // 设置 加密公钥
  const data = encryptStr.encrypt(str.toString()) // 进行加密
  return data
}

Vue.config.productionTip = false

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')

