import axios from '../utils/axios'


export const getToiletData =()=>{
    return axios.get('hdstorApi/app/toilet/getToilet');
}

export const getDeviceData =()=>{
    return axios.get('hdstorApi/app/basic/devicePageLists');
}

export const getParkingData =()=>{
    return axios.get('hdstorApi/app/basic/parkinglot');
}

//获取当天过车数据
export const getFlowDay=()=>{
    return axios.get('hdstorApi/app/basic/flowday')
}
//获取当天过车数据，按2小时计
export const getTowHours=()=>{
    return axios.get('hdstorApi/app/basic/twohours')
}

//获取报警主机报警
export const getAlarmhostsAll=(code)=>{
    return axios.get('hdstorApi/app/basic/alarmhostsAll?nodeCode='+code)
}

//获取获取车速看，出入
export const getCarInOutCount =(params)=>{
    return axios.get('hdstorApi/app/basic/conditionsCount',{
        params:params
    })
}

//获取报警记录
export const getAlarmRecord =(params)=>{
    return axios.get('hdstorApi/app/basic/alarmrecord',{
        params:params
    })
}
//获取近7天/1月车辆出入
export const getCarInOutDayCount = (params)=>{
    return axios.get('hdstorApi/app/basic/carYMD',{
        params:params
    })
}

//获取入园人数
export const getPersonNum=()=>{
    return axios.get('hdstorApi/app/ticket/personNo')
}

//获取人流
export const getPersonDayCount =(params)=>{
    return axios.get('hdstorApi/app/ticket/carYMD',{
        params:params
    })
}