<template>
  <div :style="{height:height,width:width}"></div>
</template>

<script>

import * as echarts from 'echarts';
export default {
  name: "pieChart",
  props:{
    width: {
      type: String,
      default: '100%'
    },
    height: {
      type: String,
      default: '100%'
    },
    stepNum:{
      type:Number,
      default:50
    },
    chartData: {
      type:Object,
      default:null
    },
  },
  data(){
    return {
      chart:null
    }
  },
  watch: {
    chartData: {
      deep: true,
      handler(val) {
        this.setOptions(val)
      }
    }
  },
  mounted() {
    this.initChart();
  },
  methods:{
    initChart(){
      this.chart = echarts.init(this.$el);
      if(this.chartData){
        this.setOptions(this.chartData);
      }
    },
    setOptions(chartData){
      let xAxis =chartData.linx
      let series=[];
      chartData.value.forEach((item,index)=>{
        series.push({
          name:chartData.linName[index],
          data: item,
          type: 'line',
          smooth: true
        })
      })

      let option = {
        series: series,
        xAxis: {
          show: true,
          type: "category",
          boundaryGap: false,
          axisLabel: {
            textStyle: {
              color: "#fff",
            },
          },
          axisLine: {
            lineStyle: {
              color: "white",
            },
          },
          data: xAxis
        },
        yAxis: {
          show: true,
          interval: this.stepNum,
          splitArea: {
            show: true,
            areaStyle: {
              color: "rgba(0,0,0,0)",
            },
          },
          // name: that.charts.unit,
          type: "value",
          axisLabel: {
            formatter: "{value}",
            textStyle: {
              color: "#ffffff",
            },
          },
          splitLine: {
            lineStyle: {
              color: "#54638f",
            },
          },

          axisLine: {
            show: false,
            lineStyle: {
              color: "#ffffff",
            },
          },
        },

        color:['#FF0087','#80FFA5'],
        tooltip: {
          trigger: "axis",
        },
        legend: {
          bottom: "18%",
          textStyle: {
            fontSize: 12,
            color: "F1F1F3",
          },
          // right: "4%",
        },
        grid: {
          top: "10%",
          left: "4%",
          right: "4%",
          bottom: "30%",
          containLabel: true,
        },
      };

      this.chart.setOption(option);
    }
  }
}


</script>

<style scoped>

</style>