import { render, staticRenderFns } from "./ota.vue?vue&type=template&id=31c4ae3a&scoped=true&"
import script from "./ota.vue?vue&type=script&lang=js&"
export * from "./ota.vue?vue&type=script&lang=js&"
import style0 from "./ota.vue?vue&type=style&index=0&id=31c4ae3a&prod&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "31c4ae3a",
  null
  
)

export default component.exports